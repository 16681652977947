// import SquareWOFF from "../assets/fonts/EuclidSquare-Regular.woff";
// import SquareBoldWOFF from "../assets/fonts/EuclidSquare-Bold.woff";
// import SquareSemiBoldWOFF from "../assets/fonts/EuclidSquare-SemiBold.woff";
// import SquareItalicWOFF from "../assets/fonts/EuclidSquare-Italic.woff";
// import SquareLightWOFF from "../assets/fonts/EuclidSquare-Light.woff";
// import SquareMediumWOFF from "../assets/fonts/EuclidSquare-Medium.woff";
import ArialBlack from "../assets/fonts/ArialBlack.ttf";
import Ebrima from "../assets/fonts/ebrima.ttf";
import SquareKano from "../assets/fonts/Kano.otf"

const arialBlack = {
  fontFamily: "ArialBlack",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('EuclidSquare'),
		local('EuclidSquare-Regular'),
		url(${ArialBlack}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const ebrima = {
  fontFamily: "Ebrima",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('EuclidSquare'),
		local('EuclidSquare-Regular'),
		url(${Ebrima}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const kano = {
  fontFamily: "Kano",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('EuclidSquare'),
		local('EuclidSquare-Regular'),
		url(${SquareKano}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

// const square = {
//   fontFamily: "Kano",
//   fontStyle: "normal",
//   fontDisplay: "swap",
//   fontWeight: 400,
//   src: `
// 		local('EuclidSquare'),
// 		local('EuclidSquare-Regular'),
// 		url(${SquareWOFF}) format('woff')
// 	`,
//   unicodeRange:
//     "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
// };

// const squareLight = {
//   fontFamily: "Kano",
//   fontStyle: "normal",
//   fontDisplay: "swap",
//   fontWeight: 300,
//   src: `
// 		local('EuclidSquare'),
// 		local('EuclidSquare-Light'),
// 		url(${SquareLightWOFF}) format('woff')
// 	`,
//   unicodeRange:
//     "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
// };

// const squareMedium = {
//   fontFamily: "Kano",
//   fontStyle: "medium",
//   fontDisplay: "swap",
//   fontWeight: 500,
//   src: `
// 		local('EuclidSquare'),
// 		local('EuclidSquare-Medium'),
// 		url(${SquareMediumWOFF}) format('woff')
// 	`,
//   unicodeRange:
//     "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
// };

// const squareSemiBold = {
//   fontFamily: "Kano",
//   fontStyle: "normal",
//   fontDisplay: "swap",
//   fontWeight: 600,
//   src: `
// 		local('EuclidSquare-SemiBold'),
// 		local('EuclidSquare-SemiBold'),
// 		url(${SquareSemiBoldWOFF}) format('woff')
// 	`,
//   unicodeRange:
//     "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
// };

// const squareBold = {
//   fontFamily: "Kano",
//   fontStyle: "bold",
//   fontDisplay: "swap",
//   fontWeight: 700,
//   src: `
// 		local('EuclidSquare-Bold'),
// 		local('EuclidSquare-Bold'),
// 		url(${SquareBoldWOFF}) format('woff')
// 	`,
//   unicodeRange:
//     "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
// };

// const squareItalic = {
//   fontFamily: "Kano",
//   fontStyle: "italic",
//   fontDisplay: "swap",
//   fontWeight: 400,
//   src: `
// 		local('EuclidSquare-Italic'),
// 		local('EuclidSquare-Italic'),
// 		url(${SquareItalicWOFF}) format('woff')
// 	`,
//   unicodeRange:
//     "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
// };

const fonts = [arialBlack, ebrima, kano];

export default fonts;
