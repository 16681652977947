import { notification } from 'antd';

export const openWarningNotification = (txt) => {
	notification.warning({
		message: '',
		placement: "bottomRight",
		duration: 5,
		description: txt,
		style: {
			display: 'flex',
			textAlign: 'center',
			alignItems: 'center',
			justifyContent: 'center',
			zIndex: 99999999
		}
	});
}

export const openSuccessNotification = (txt) => {
	notification.success({
		message: '',
		placement: "bottomRight",
		duration: 5,
		description: txt,
		style: {
			display: 'flex',
			textAlign: 'center',
			alignItems: 'center',
			justifyContent: 'center',
			zIndex: 99999999
		}
	});
}
