import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Slide,
  Typography,
} from "@material-ui/core";
import { prettifySeconds, secondsUntilBlock, shorten, trim } from "../../helpers";
import { bondAsset, calcBondDetails, changeApproval } from "../../slices/BondSlice";
import { useWeb3Context } from "src/hooks/web3Context";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { Skeleton } from "@material-ui/lab";
import useDebounce from "../../hooks/Debounce";
import { error } from "../../slices/MessagesSlice";
import './bondPurchase.scss'
import DownIcon from "../../assets/icons/down.png";
import { useTranslation } from 'react-i18next';

function BondPurchase({ theme, bond, slippage, recipientAddress }) {
  const { t } = useTranslation();
  const isLight = theme === "light" ? true : false;
  const SECONDS_TO_REFRESH = 60;
  const dispatch = useDispatch();
  const { provider, address, chainID } = useWeb3Context();

  const [isShowSelect, changeShowSelect] = useState(false);
  const [quantity, setQuantity] = useState("");
  const [selVal, setSelVal] = useState(0);
  const [tokenVal, setTokenVal] = useState("USDT");
  const [secondsToRefresh, setSecondsToRefresh] = useState(SECONDS_TO_REFRESH);

  const currentBlock = useSelector(state => {
    return state.app.currentBlock;
  });

  const isBondLoading = useSelector(state => state.bonding.loading ?? true);

  const pendingTransactions = useSelector(state => {
    return state.pendingTransactions;
  });

  const vestingPeriod = () => {
    const vestingBlock = parseInt(currentBlock) + parseInt(bond.vestingTerm);
    const seconds = secondsUntilBlock(currentBlock, vestingBlock);
    return prettifySeconds(seconds, "day");
  };
  const handleChange = (number, str) => {
    setSelVal(number)
    setTokenVal(str)
    changeShowSelect(false)
    clearInput();
    // dispatch(calcBondDetails({ bond, value: quantity, provider, networkID: chainID, selVal: event.target.value }));
  };
  async function onBond() {
    if (quantity === "") {
      dispatch(error("Please enter a value!"));
    } else if (isNaN(quantity)) {
      dispatch(error("Please enter a valid value!"));
    } else if (bond.interestDue > 0 || bond.pendingPayout > 0) {
      const shouldProceed = window.confirm(
        "You have an existing bond. Bonding will reset your vesting period and forfeit rewards. We recommend claiming rewards first or using a fresh wallet. Do you still want to proceed?",
      );
      if (shouldProceed) {
        await dispatch(
          bondAsset({
            value: quantity,
            slippage,
            bond,
            networkID: chainID,
            selVal,
            provider,
            address: recipientAddress || address,
          }),
        );
      }
    } else {
      await dispatch(
        bondAsset({
          value: quantity,
          slippage,
          bond,
          networkID: chainID,
          selVal,
          provider,
          address: recipientAddress || address,
        }),
      );
      clearInput();
    }
  }

  const clearInput = () => {
    setQuantity("");
  };

  const hasAllowance = useCallback(() => {
    if (tokenVal == 'AVAX') {
      return true;
    }
    return (!bond.allowance ? false : bond.allowance[selVal] > 0)
  }, [selVal]);

  const handlerSelect = () => {

    changeShowSelect(true)
  }

  const setMax = () => {
    let maxQ;
    if (bond.maxBondPrice * bond.bondPrice < Number(bond.balance)) {
      // there is precision loss here on Number(bond.balance)
      maxQ = bond.maxBondPrice * bond.bondPrice.toString();
    } else {

      maxQ = bond.name == 'wAVAX' ? bond.balance[selVal + 1] : bond.balance[selVal];
    }
    setQuantity(maxQ);
  };

  const bondDetailsDebounce = useDebounce(quantity, 1000);

  useEffect(() => {
    dispatch(calcBondDetails({ bond, value: quantity, provider, networkID: chainID, selVal }));
  }, [bondDetailsDebounce, provider, chainID, address]);

  useEffect(() => {
    let interval = null;
    if (secondsToRefresh > 0) {
      interval = setInterval(() => {
        setSecondsToRefresh(secondsToRefresh => secondsToRefresh - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      dispatch(calcBondDetails({ bond, value: quantity, provider, networkID: chainID, selVal }));
      setSecondsToRefresh(SECONDS_TO_REFRESH);
    }
    return () => clearInterval(interval);
  }, [secondsToRefresh, quantity]);

  const onSeekApproval = async () => {
    dispatch(changeApproval({ address, bond, provider, networkID: chainID, selVal }));
  };
  const displayUnits = bond.displayUnits;
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-around" flexWrap="wrap">
        <FormControl className="ohm-input" variant="outlined" color="primary" fullWidth>
          <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            type="number"
            value={quantity}
            onChange={e => setQuantity(e.target.value)}
            // startAdornment={<InputAdornment position="start">$</InputAdornment>}
            labelWidth={55}
            endAdornment={
              <InputAdornment position="end">
                <Button variant="text" onClick={setMax}>
                  Max
                </Button>
                <Box className="selectBox">
                  <Box display="flex" className="selectVal" onClick={handlerSelect}>
                    <Typography variant="h4">
                      {bond.name == 'aFTMb' ? "aFTMb" : tokenVal}
                    </Typography>
                    {bond.isLP && <img src={DownIcon} className={["DownIcon", isShowSelect ? "select-caret" : ""].join(' ')}></img>}
                  </Box>
                  {bond.isLP &&
                    <Box className={["selectList", isShowSelect ? "show" : "", !isLight ? "selectListDark" : ""].join(' ')}>

                      <Box className="selectItem" onClick={() => {
                        handleChange(0, "USDT")
                      }}>
                        <Typography variant="h6">
                          USDT
                        </Typography>
                      </Box>
                      <Box className="selectItem" onClick={() => {
                        handleChange(1, "LP")
                      }}>
                        <Typography variant="h6">
                          LP
                        </Typography>
                      </Box>
                    </Box>}
                </Box>

              </InputAdornment>
            }
          />
        </FormControl>
        {!bond.isAvailable
          ?
          <Button
            variant="contained"
            color="primary"
            id="bond-btn"
            className="transaction-button"
            disabled={true}
          >
            {t('common.soldout')}
          </Button> :
          hasAllowance() ? (
            <Button
              variant="contained"
              color="primary"
              id="bond-btn"
              className="transaction-button"
              disabled={isPendingTxn(pendingTransactions, "bond_" + bond.name)}
              onClick={onBond}
            >
              {txnButtonText(pendingTransactions, "bond_" + bond.name, t('bond.bond'))}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              id="bond-approve-btn"
              className="transaction-button"
              disabled={isPendingTxn(pendingTransactions, "approve_" + bond.name)}
              onClick={onSeekApproval}
            >
              {txnButtonText(pendingTransactions, "approve_" + bond.name, t('bond.approve'))}
            </Button>
          )}

        {!hasAllowance() && (
          <div className="help-text">
            <em>
              <Typography variant="body2">
                Note: <br />
                {bond.name == 'IND_usdc_lp' && <>Create LP with a single click using USDT. You don't need to create LP manually.<br /></>}
                The "Approve" transaction is only needed when bonding for the first time; subsequent bonding only
                requires you to perform the "Bond" transaction.
              </Typography>
            </em>
          </div>
        )}
      </Box>

      <Slide direction="left" in={true} mountOnEnter unmountOnExit {...{ timeout: 553 }}>
        <Box className="bond-data">
          <div className="data-row">
            <Typography>{t('bond.yourBalance')}</Typography>
            <Typography>
              {
                (isBondLoading && !bond.balance) ? (
                  <Skeleton width="100px" />
                ) : (
                  <>
                    {bond.name && bond.balance && bond.name == 'wAVAX' && <>{trim(bond.balance[selVal + 1], 4)} {(selVal == '0' ? bond.displayUnits : "AVAX")}</>}
                    {bond.balance && bond.name != 'wAVAX' && <> {trim(bond.balance[selVal], 4)}  {bond.name == 'aFTMb' ? "aFTMb" : selVal == '0' ? "USDT" : bond.displayUnits}</>}
                  </>
                )}
            </Typography>
          </div>

          <div className={`data-row`}>
            <Typography>{t('bond.youWillGet')}</Typography>
            <Typography id="bond-value-id" className="price-data">
              {isBondLoading ? <Skeleton width="100px" /> : `${trim(bond.bondQuote, 4) || "0"} IND`}
            </Typography>
          </div>

          <div className={`data-row`}>
            <Typography>{t('bond.maxYouBuy')}</Typography>
            <Typography id="bond-value-id" className="price-data">
              {isBondLoading ? <Skeleton width="100px" /> : `${trim(bond.maxBondPrice, 4) || "0"} IND`}
            </Typography>
          </div>

          <div className="data-row">
            <Typography>{t('bond.ROIPercent')}</Typography>
            <Typography>
              {isBondLoading ? <Skeleton width="100px" /> : `${trim(bond.bondDiscount * 100, 2)}%`}
            </Typography>
          </div>

          <div className="data-row">
            <Typography>{t('bond.debt')}</Typography>
            <Typography>
              {isBondLoading ? <Skeleton width="100px" /> : `${trim(bond.debtRatio / 10000000, 2)}%`}
            </Typography>
          </div>

          <div className="data-row">
            <Typography>{t('bond.vesting')}</Typography>
            <Typography>{isBondLoading && currentBlock ? <Skeleton width="100px" /> : vestingPeriod()}</Typography>
          </div>

          {recipientAddress !== address && (
            <div className="data-row">
              <Typography>Recipient</Typography>
              <Typography>{isBondLoading ? <Skeleton width="100px" /> : shorten(recipientAddress)}</Typography>
            </div>
          )}
        </Box>
      </Slide>
    </Box>
  );
}

export default BondPurchase;