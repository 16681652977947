import { ethers } from "ethers";
import { addresses } from "../constants";
import { abi as ierc20Abi } from "../abi/IERC20.json";
import { abi as sOHMv2 } from "../abi/sOhmv2.json";
import { setAll } from "../helpers";

import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";// TODO: this type definition needs to move out of BOND.
import { RootState } from "src/store";
import { IBaseAddressAsyncThunk, ICalcUserBondDetailsAsyncThunk } from "./interfaces";
import { abi as OlympusStaking } from "../abi/OlympusStakingv2.json";
export const getBalances = createAsyncThunk(
  "account/getBalances",
  async ({ address, networkID, provider }: IBaseAddressAsyncThunk) => {
    const ohmContract = new ethers.Contract(addresses[networkID].OHM_ADDRESS as string, ierc20Abi, provider);
    const ohmBalance = await ohmContract.balanceOf(address);
    const sohmContract = new ethers.Contract(addresses[networkID].SOHM_ADDRESS as string, ierc20Abi, provider);
    const sohmBalance = await sohmContract.balanceOf(address);
    const staking = new ethers.Contract(addresses[networkID].STAKING_ADDRESS as string, OlympusStaking, provider);
    const info = await staking.warmupInfo(address)
    const warmBalance = await sohmContract.balanceForGons(info.gons)

    // const wsohmContract = new ethers.Contract(addresses[networkID].WSOHM_ADDRESS as string, ierc20Abi, provider);
    // const wsohmBalance = await wsohmContract.balanceOf(address);

    return {
      balances: {
        ohm: ethers.utils.formatUnits(ohmBalance, "gwei"),
        sohm: ethers.utils.formatUnits(sohmBalance, "gwei"),
        warmBalance: ethers.utils.formatUnits(warmBalance, "gwei"),
        // wsohmBalance: ethers.utils.formatUnits(wsohmBalance, "gwei"),
      },
    };
  },
);

interface IUserAccountDetails {
  balances: {
    warmBalance: string;
    ohm: string;
    sohm: string;
  };
  staking: {
    ohmStake: number;
    ohmUnstake: number;
  };
  bonding: {
    daiAllowance: number;
  };
}

export const loadAccountDetails = createAsyncThunk(
  "account/loadAccountDetails",
  async ({ networkID, provider, address }: IBaseAddressAsyncThunk) => {
    let ohmBalance = 0;
    let sohmBalance = 0;
    let stakeAllowance = 0;
    let unstakeAllowance = 0;
    let warmBalance = 0
    let GLAlock = false
    let wsohmBalance = 0
    if (addresses[networkID].OHM_ADDRESS) {
      const ohmContract = new ethers.Contract(addresses[networkID].OHM_ADDRESS as string, ierc20Abi, provider);
      ohmBalance = await ohmContract.balanceOf(address);
      stakeAllowance = await ohmContract.allowance(address, addresses[networkID].STAKING_HELPER_ADDRESS);
    }

    if (addresses[networkID].SOHM_ADDRESS) {
      const sohmContract = new ethers.Contract(addresses[networkID].SOHM_ADDRESS as string, sOHMv2, provider);
      sohmBalance = await sohmContract.balanceOf(address);
      unstakeAllowance = await sohmContract.allowance(address, addresses[networkID].STAKING_ADDRESS);
      sohmBalance = await sohmContract.balanceOf(address);
      const staking = new ethers.Contract(addresses[networkID].STAKING_ADDRESS as string, OlympusStaking, provider);
      const info = await staking.warmupInfo(address)
      const epoch = await staking.epoch()
      // epoch.number expiry  
      GLAlock = (Number(info.expiry) > Number(epoch.number))
      warmBalance = await sohmContract.balanceForGons(info.gons)
    }

    // if (addresses[networkID].WSOHM_ADDRESS) {
      // const wsohmContract = new ethers.Contract(addresses[networkID].WSOHM_ADDRESS as string, ierc20Abi, provider);
      // wsohmBalance = await wsohmContract.balanceOf(address);
    // }

    return {
      balances: {
        ohm: ethers.utils.formatUnits(ohmBalance, "gwei"),
        sohm: ethers.utils.formatUnits(sohmBalance, "gwei"),
        warmBalance: ethers.utils.formatUnits(warmBalance, "gwei"),
        wsohmBalance: ethers.utils.formatUnits(wsohmBalance, "gwei"),
      },
      staking: {
        ohmStake: +stakeAllowance,
        ohmUnstake: +unstakeAllowance,
      },
      lock: {
        GLAlock: GLAlock
      },
      bonding: {
        daiAllowance: [0, 0, 0],
      }
    };
  },
);

export interface IUserBondDetails {
  allowance: number[];
  interestDue: number;
  bondMaturationBlock: number;
  pendingPayout: string; //Payout formatted in gwei.
}
export const calculateUserBondDetails = createAsyncThunk(
  "account/calculateUserBondDetails",
  async ({ address, bond, networkID, provider }: ICalcUserBondDetailsAsyncThunk) => {
    if (!address) {
      return {
        bond: "",
        displayName: "",
        bondIconSvg: "",
        isLP: false,
        allowance: [0, 0],
        balance: ["0", "0"],
        interestDue: 0,
        bondMaturationBlock: 0,
        pendingPayout: "",
      };
    }
    // dispatch(fetchBondInProgress());

    // Calculate bond details.
    const bondContract = bond.getContractForBond(networkID, provider);
    const reserveContract = bond.getContractForReserve(networkID, provider);

    const wbnbContract = new ethers.Contract(addresses[networkID].USDT_ADDRESS as string, ierc20Abi, provider);


    let interestDue, pendingPayout, bondMaturationBlock;

    const bondDetails = await bondContract.bondInfo(address);
    interestDue = bondDetails.payout / Math.pow(10, 9);
    bondMaturationBlock = +bondDetails.vesting + +bondDetails.lastBlock;
    pendingPayout = await bondContract.pendingPayoutFor(address);

    let allowance,
      balance = 0;
    allowance = await reserveContract.allowance(address, bond.getAddressForBondHelper(networkID));
    balance = await reserveContract.balanceOf(address);
    const avaxBalance = await provider.getBalance(address);
    let wbnbAllowance
    if (bond.isLP) {
      wbnbAllowance = await wbnbContract.allowance(address, bond.getAddressForBondHelper(networkID));
    } else {
      // if (bond.name == 'MIM') {
      //   wbnbAllowance = await reserveContract.allowance(address, bond.getAddressForBond(networkID));
      // } else {
      wbnbAllowance = await reserveContract.allowance(address, bond.getAddressForBond(networkID));
      // }
    }
    let wbnbBalance = bond.name == 'aFTMb' ? ethers.utils.formatUnits(balance, 18) : ethers.utils.formatUnits((await wbnbContract.balanceOf(address)), 18)
    // formatEthers takes BigNumber => String
    const balanceVal = ethers.utils.formatEther(balance);
    // balanceVal should NOT be converted to a number. it loses decimal precision
    return {
      bond: bond.name,
      displayName: bond.displayName,
      bondIconSvg: bond.bondIconSvg,
      isLP: bond.isLP,
      allowance: [Number(wbnbAllowance), Number(allowance)],
      balance: [wbnbBalance, balanceVal, ethers.utils.formatEther(avaxBalance)],
      interestDue,
      bondMaturationBlock,
      pendingPayout: ethers.utils.formatUnits(pendingPayout, "gwei"),
    };
  },
);

export const calculateUserSuperBondDetails = createAsyncThunk(
  "account/calculateUserSuperBondDetails",
  async ({ address, bond, networkID, provider }: ICalcUserBondDetailsAsyncThunk) => {
    if (!address) {
      return {
        bond: "",
        displayName: "",
        bondIconSvg: "",
        isLP: false,
        allowance: [0, 0],
        balance: ["0", "0"],
        interestDue: 0,
        bondMaturationBlock: 0,
        pendingPayout: "",
      };
    }
    // dispatch(fetchBondInProgress());

    // Calculate bond details.
    const bondContract = bond.getContractForBond(networkID, provider);
    const reserveContract = bond.getContractForReserve(networkID, provider);

    const wbnbContract = new ethers.Contract(addresses[networkID].USDT_ADDRESS as string, ierc20Abi, provider);


    let interestDue, pendingPayout, bondMaturationBlock;

    const bondDetails = await bondContract.rewardBondInfo(address);
    interestDue = bondDetails.payout / Math.pow(10, 9);
    bondMaturationBlock = +bondDetails.vesting + +bondDetails.lastBlock;
    pendingPayout = await bondContract.pendingPayoutForReward(address);

    let allowance,
      balance = 0;
    allowance = await reserveContract.allowance(address, bond.getAddressForBondHelper(networkID));
    balance = await reserveContract.balanceOf(address);
    const avaxBalance = await provider.getBalance(address);
    let wbnbAllowance
    if (bond.isLP) {
      wbnbAllowance = await wbnbContract.allowance(address, bond.getAddressForBondHelper(networkID));
    } else {
      // if (bond.name == 'MIM') {
      //   wbnbAllowance = await reserveContract.allowance(address, bond.getAddressForBond(networkID));
      // } else {
      wbnbAllowance = await reserveContract.allowance(address, bond.getAddressForBond(networkID));
      // }
    }
    let wbnbBalance = bond.name == 'aFTMb' ? ethers.utils.formatUnits(balance, 18) : ethers.utils.formatUnits((await wbnbContract.balanceOf(address)), 18)
    // formatEthers takes BigNumber => String
    const balanceVal = ethers.utils.formatEther(balance);
    // balanceVal should NOT be converted to a number. it loses decimal precision
    return {
      bond: bond.name,
      displayName: bond.displayName,
      bondIconSvg: bond.bondIconSvg,
      isLP: bond.isLP,
      allowance: [Number(wbnbAllowance), Number(allowance)],
      balance: [wbnbBalance, balanceVal, ethers.utils.formatEther(avaxBalance)],
      interestDue,
      bondMaturationBlock,
      pendingPayout: ethers.utils.formatUnits(pendingPayout, "gwei"),
    };
  },
);
interface IAccountSlice {
  bonds: { [key: string]: IUserBondDetails };
  superBonds: { [key: string]: IUserBondDetails };
  balances: {
    ohm: string;
    sohm: string;
    warmBalance: string;
  };
  loading: boolean;
}
const initialState: IAccountSlice = {
  loading: false,
  bonds: {},
  superBonds: {},
  balances: { ohm: "", sohm: "", warmBalance: "" },
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    fetchAccountSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAccountDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadAccountDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAccountDetails.rejected, (state, { error }) => {
        state.loading = false;
        //   console.log(error);
      })
      .addCase(getBalances.pending, state => {
        state.loading = true;
      })
      .addCase(getBalances.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getBalances.rejected, (state, { error }) => {
        state.loading = false;
        //   console.log(error);
      })
      .addCase(calculateUserBondDetails.pending, state => {
        state.loading = true;
      })
      .addCase(calculateUserBondDetails.fulfilled, (state, action) => {
        if (!action.payload) return;
        const bond = action.payload.bond;
        state.bonds[bond] = action.payload;
        state.loading = false;
      })
      .addCase(calculateUserBondDetails.rejected, (state, { error }) => {
        // console.log(error);
        state.loading = false;
      })
      .addCase(calculateUserSuperBondDetails.pending, state => {
        state.loading = true;
      })
      .addCase(calculateUserSuperBondDetails.fulfilled, (state, action) => {
        if (!action.payload) return;
        const bond = action.payload.bond;
        state.superBonds[bond] = action.payload;
        state.loading = false;
      })
      .addCase(calculateUserSuperBondDetails.rejected, (state, { error }) => {
        state.loading = false;
        // console.log(error);
      });
  },
});

export default accountSlice.reducer;

export const { fetchAccountSuccess } = accountSlice.actions;

const baseInfo = (state: RootState) => state.account;

export const getAccountState = createSelector(baseInfo, account => account);
