import { useState, useEffect } from "react";
import { addresses, TOKEN_DECIMALS } from "../../constants";
import { getTokenImage } from "../../helpers";
import { useSelector } from "react-redux";
import { Link, SvgIcon, Popper, Button, Paper, Typography, Divider, Box, Fade, Slide } from "@material-ui/core";
import { ReactComponent as InfoIcon } from "../../assets/icons/info-fill.svg";
import { ReactComponent as ArrowUpIcon } from "../../assets/icons/arrow-up.svg";
import "./ohmmenu.scss";
import { useWeb3Context } from "../../hooks/web3Context";
import SohmImg from "src/assets/tokens/INDLogo.png";
import USDTImg from "src/assets/tokens/USDT.png";

function OhmMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const isEthereumAPIAvailable = window.ethereum;
  const { chainID } = useWeb3Context();

  const networkID = chainID;

  const OHM_ADDRESS = addresses[networkID].OHM_ADDRESS;
  const SOHM_ADDRESS = addresses[networkID].SOHM_ADDRESS;

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = "ohm-popper";

  // const daiAddress = dai.getAddressForReserve(networkID);
  // const fraxAddress = frax.getAddressForReserve(networkID);
  return (
    <Box
      component="div"
      onMouseEnter={e => handleClick(e)}
      onMouseLeave={e => handleClick(e)}
      id="ohm-menu-button-hover"
    >
      <Button id="ohm-menu-button" size="large" variant="contained" color="secondary" title="IND" aria-describedby={id}>
        <SvgIcon component={InfoIcon} color="primary" />
        <Typography>IND</Typography>
      </Button>

      {/* <Button id="ohm-menu-button" size="large" variant="contained"  >
        <Typography> {ChainText[chainID]} </Typography>
      </Button> */}

      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-start" transition>
        {({ TransitionProps }) => {
          return (
            <Fade {...TransitionProps} timeout={100}>
              <Paper className="ohm-menu" elevation={1}>
                <Box component="div" className="buy-tokens">
                  <Link
                    href={`https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x986d88736CC8c18069B75a3023EC3880f3b20562`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button size="large" variant="contained" color="secondary" fullWidth>
                      <Typography align="left">
                        Buy on PancakeSwap <SvgIcon component={ArrowUpIcon} htmlColor="#A3A3A3" />
                      </Typography>
                    </Button>
                  </Link>

                  <Link
                    href={`https://dexscreener.com/bsc/0x1d8E780d89b4BdBcab3cE719C47C47cf1e3490A5`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button size="large" variant="contained" color="secondary" fullWidth>
                      <Typography align="left">
                        Charts <SvgIcon component={ArrowUpIcon} htmlColor="#A3A3A3" />
                      </Typography>
                    </Button>
                  </Link>
                </Box>

                {isEthereumAPIAvailable ? (
                  <Box className="add-tokens">
                    <Divider color="secondary" />

                    <p>ADD TOKEN TO WALLET</p>
                    <Button
                      size="large"
                      variant="contained"
                      color="secondary"
                      onClick={addTokenToWallet("IND", OHM_ADDRESS, SohmImg)}
                    >
                      <div className="flex justify-content-between align-items-center">
                        <img src={SohmImg} style={{ width: 16, height: 16, marginRight: 4 }} />
                        <Typography>IND</Typography>
                      </div>
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      color="secondary"
                      onClick={addTokenToWallet("sIND", SOHM_ADDRESS, SohmImg)}
                    >
                      <div className="flex justify-content-between align-items-center">
                        <img src={SohmImg} style={{ width: 16, height: 16, marginRight: 4 }} />
                        <Typography>sIND</Typography>
                      </div>
                    </Button>
                    {/* <Button
                      variant="contained"
                      size="large"
                      color="secondary"
                      onClick={addTokenToWallet("wIND", addresses[networkID].WSOHM_ADDRESS)}
                    >
                      <Typography>wIND</Typography>

                    </Button> */}
                  </Box>
                ) : null}

                {/* <Divider color="secondary" />
                <Link
                  href=""
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button size="large" variant="contained" color="secondary" fullWidth>
                    <Typography align="left">Unstake LP Token</Typography>
                  </Button>
                </Link> */}
              </Paper>
            </Fade>
          );
        }}
      </Popper>
    </Box>
  );
}



const addTokenToWallet = (tokenSymbol, tokenAddress, tokenImage) => async () => {
  if (window.ethereum) {
    try {
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: TOKEN_DECIMALS,
            image: tokenImage,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
};




export default OhmMenu;
