//import { ReactComponent as ForumIcon } from "../../assets/icons/forum.svg";
//import { ReactComponent as GovIcon } from "../../assets/icons/governance.svg";
// import { ReactComponent as DocsIcon } from "../../assets/icons/book.svg";
import { ReactComponent as Contract } from "../../assets/icons/contract.svg"
// import { ReactComponent as FeedbackIcon } from "../../assets/icons/feedback.svg";

import { SvgIcon } from "@material-ui/core";

const externalUrls = [
  {
    title: "IND Contract",
    url: "",
    icon: <SvgIcon color="primary" component={Contract} />,
  }, 
   {
    title: "Alpha IND Contract",
    url: "https://snapshot.org/#/safeohm.eth",
    icon: <SvgIcon color="primary" component={Contract} />,
  }, 
   {
    title: "Pubic Sale Contract",
    url: "",
    icon: <SvgIcon color="primary" component={Contract} />,
  }, 
  {
    title: "MultiSig Contract",
    url: "",
    icon: <SvgIcon color="primary" component={Contract} />,
  },   
];

export default externalUrls;
