import { StableBond, LPBond, NetworkID, CustomBond } from "src/lib/Bond";
import { ReactComponent as USDCImg } from "src/assets/tokens/usdc.svg";
import { ReactComponent as SohmWbnbImg } from "src/assets/tokens/usdc-ind.svg";
import { abi as BondHelperContract } from "src/abi/BondWbnbHelper.json";
import { abi as BondOhmLusdContract } from "src/abi/bonds/OhmLusdContract.json";
import { abi as BondContract } from "src/abi/BondContract.json";
import { abi as BondaFTMbContract } from "src/abi/bonds/aFTMbContract.json";
import { abi as ReserveOhmLusdContract } from "src/abi/reserves/OhmLusd.json";
import { abi as BondOhmLusdHelperContract } from "src/abi/BondHelper.json";
import { BigNumberish } from "ethers";
import { addresses } from "src/constants";
import { ReactComponent as aFTMbSVG } from "src/assets/tokens/aftmb.svg";
/*
 wftm :  0x6125B48843E1653869B18889e663852032C24cC0
  aftmb ： 0xBcFDBc89EB372112b5852325E13cf4eFE953E31b
  wftm_usdc_lp: 0x464FA33378806c5F714E9Cc8b7f4640fEC84f468
  bond : 0xf17339E2C87Ebd158857d2FE6e331Fc80d7A2c42
 */
export const USDT = new StableBond({
  name: "usdt",// "USDT",
  displayName: "USDT",//"USDT",
  bondToken: "USDT",//"USDT",
  bondIconSvg: USDCImg,
  isAvailable: true,
  bondContractABI: BondOhmLusdContract,
  bondHelperContractABI: BondHelperContract,
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0xeaC0df0276e0749f7A7C012744B63145D5b326A6",
      reserveAddress: "0x04068da6c83afcfa0e13ba15a6696662335d5b75",
      bondHelperAddress: "0x8F120b7d4b4b8a439cA6b436aF2C87412C07E82f",
    },
    [NetworkID.Testnet]: {
      bondAddress: "0x69E96aDE46a2094433003a6EBade6B5da33542F8",
      reserveAddress: "0x8e088E79d554a75d511b390E021839f7877Ce0Aa",
      bondHelperAddress: "0x420135992f16590e4721a88C6602a27202170e50",
    },
  },
});
export const IND_USDC = new LPBond({
  name: "IND_usdc_lp",
  displayName: "IND-USDT LP",
  bondToken: "INDUSDC",
  isAvailable: true,
  bondIconSvg: SohmWbnbImg,
  bondContractABI: BondContract,
  reserveContract: ReserveOhmLusdContract,
  bondHelperContractABI: BondOhmLusdHelperContract,
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0x0d9C244B66fC3A252A7c284152De3110EB007D7a",
      reserveAddress: "0x1d8E780d89b4BdBcab3cE719C47C47cf1e3490A5",
      bondHelperAddress: "0x3222EA686FaA224Ba0A55217f765468F33eDC0eC",
    },
    [NetworkID.Testnet]: {
      // NOTE (appleseed-lusd): using ohm-dai rinkeby contracts
      // bondAddress: "0x1D082276ddF7E6D89b2310b051cE9069482ae620", //Bond
      // reserveAddress: "0xE77BB6E129cF86a27A47717414BA578a188490B6", //lp
      // bondHelperAddress: "0x166c23E49F56C3dFE5aA236064E02c73A13cC274", //bondHelper

      bondAddress: "0x0d9C244B66fC3A252A7c284152De3110EB007D7a",
      reserveAddress: "0x1d8E780d89b4BdBcab3cE719C47C47cf1e3490A5",
      bondHelperAddress: "0x3222EA686FaA224Ba0A55217f765468F33eDC0eC",
    },
  },
  lpUrl:
    "https://app.sushi.com/add/0x383518188C0C6d7730D91b2c03a03C837814a899/0x5f98805A4E8be255a32880FDeC7F6728C6568bA0",
});
export const aFTMb = new CustomBond({
  name: "aFTMb",
  displayName: "aFTMb",
  bondToken: "aFTMb",
  bondIconSvg: aFTMbSVG,
  bondContractABI: BondaFTMbContract,
  bondHelperContractABI: BondHelperContract,
  isAvailable: true,
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0xD8D68b9993F0FC74A6D4398193d5b4f3B969Adf1",
      reserveAddress: "0xB42bF10ab9Df82f9a47B86dd76EEE4bA848d0Fa2",
      bondHelperAddress: "0x25b78A4D1bC21a47Fa3E16B55DA45127Eccb6a34",
    },
    [NetworkID.Testnet]: {
      bondAddress: "0x636b30fdD2E183b7A5d72AC31e1A48953cDBe205",
      reserveAddress: "0xBcFDBc89EB372112b5852325E13cf4eFE953E31b",
      bondHelperAddress: "0xFB47b85914453eCD8F85325A2e91a657B16b56b4",
    },
  },
  customTreasuryBalanceFunc: async function (this: CustomBond, networkID, provider) {
    const ethBondContract = this.getContractForBond(networkID, provider);
    let ethPrice: BigNumberish = await ethBondContract.assetPrice();
    ethPrice = Number(ethPrice.toString()) / Math.pow(10, 6);
    const token = this.getContractForReserve(networkID, provider);
    let ethAmount: BigNumberish = await token.balanceOf(addresses[networkID].TREASURY_ADDRESS);
    ethAmount = Number(ethAmount.toString()) / Math.pow(10, 18);
    return ethAmount * ethPrice;
  },
});
// HOW TO ADD A NEW BOND:
// Is it a stableCoin bond? use `new StableBond`
// Is it an LP Bond? use `new LPBond`
// Add new bonds to this array!!
export const allBonds = [IND_USDC];
// export const allBonds = [IND_USDC, USDT, aFTMb];
export const allBondsMap = allBonds.reduce((prevVal, bond) => {
  return { ...prevVal, [bond.name]: bond };
}, {});

// Debug Log
// console.log(allBondsMap);
export default allBonds;
