// NOTE could get this from an outside source since it changes slightly over time
// export const BLOCK_RATE_SECONDS = 0.9; //FTM
export const BLOCK_RATE_SECONDS = 3; //TODO:BSC
export const TOKEN_DECIMALS = 9;
interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  56: {
    OHM_ADDRESS: "0x986d88736CC8c18069B75a3023EC3880f3b20562", //
    STAKING_ADDRESS: "0x48112F6475A50C72BDd23EB7f63c3A83B32bB1D3",
    STAKING_HELPER_ADDRESS: "0x450628244ec24a1aACD1714F42E9e15C801177ff",
    SOHM_ADDRESS: "0x6605Bb831bd024E6b0862522873B1d4352926817",
    DISTRIBUTOR_ADDRESS: "0x3aEB0c49Eb3ee143c708365399079a97698f51d0",
    BONDINGCALC_ADDRESS: "0xC6F398a545c6CbF18d8E443D80B70284cE846EF2",
    TREASURY_ADDRESS: "0xC0908Bd62618bF499D7CECDB37dEe71f8DEF94F6",
    USDT_ADDRESS: "0x55d398326f99059fF775485246999027B3197955", //
    DAO_ADDRESS: '0xA067054d7Ef642d4c0Da0fd7D2D6ab37DbC2A4FD',
    LP_ADDRESS: '0x1d8E780d89b4BdBcab3cE719C47C47cf1e3490A5',
    REDEEM_HELPER_ADDRESS: "0xB8AfDb5d735d6e38773A951035504659a297Ef17",
    ALPHA_TOKEN_ADDRESS: '0xe86726506B524c3F60e2b4783123A20266BABf89', //
    MIGRATION_ADDRESS:'0x4B80F688161b4880b4Fe001b406C4017DD0Ea80c',
    INVITE_ADDRESS:'0x02f0c60665720694FE5B77A863E39b20ec0460E6',
    TEAM_ADDRESS:'0x1F47a12a45557C0F4D2637c5Aaaa0ff292166BF0',
    TEAM_AMOUNT_ADDRESS:'0x1C3b6e9E9015A19A174DBbea2967282A1C835e23',
  },
  97: {
    // OHM_ADDRESS: "0x114BbF1e193E11bC776eF25832c0e3cc2a1A4693", //
    // STAKING_ADDRESS: "0x3277EA160Da242B673339B65372c1a2F9d3d8Db9",
    // STAKING_HELPER_ADDRESS: "0x799Cc9dA894839d059a88fCf5Ed03EAc613aD3AB",
    // SOHM_ADDRESS: "0x08877aBDca52Fa7128e716b856b60c56269650e9",
    // DISTRIBUTOR_ADDRESS: "0x62192fCC9B165099c9c0AB077333FD117db7e211",
    // BONDINGCALC_ADDRESS: "0x8bDd00B181A8f741cee6f75352365BA3F40A0874",
    // TREASURY_ADDRESS: "0xC7f6fddCf0De1DC7D9a26b2Ea09D21fe26Bfe867",
    // USDT_ADDRESS: "0xA9B859cEE2015505129D0385C80273E6678D9B11", //
    // DAO_ADDRESS: '0xA58958e2E6bb289E20ee4e73d8BF3909f99aDbCe',
    // LP_ADDRESS: '0xE77BB6E129cF86a27A47717414BA578a188490B6',
    // REDEEM_HELPER_ADDRESS: "0x0d26D2b0FA7527E29486B72D52918129E638393d",
    // ALPHA_TOKEN_ADDRESS: '0x65a102557C650d6aCfe472136Cc1CC84d2A248d1', //
    // MIGRATION_ADDRESS: '0xF409EDA48e58285B54CD94F82C485DD08F9f2841', //
    // INVITE_ADDRESS: '0xa1aa940735ddb3bb4428649174e33b75b87a0080',
    // TEAM_ADDRESS:'0x1F47a12a45557C0F4D2637c5Aaaa0ff292166BF0',
    // TEAM_AMOUNT_ADDRESS:'0x1C3b6e9E9015A19A174DBbea2967282A1C835e23',


    OHM_ADDRESS: "0xbD84f8cccb39856ce8748a304763d5E02A02E1bD", //
    STAKING_ADDRESS: "0xf90C4e9180b0DE96D888E6E4E51783723284F097",
    STAKING_HELPER_ADDRESS: "0x4cb900CcFaA8e6E6DCaD800070EbCA157c99264b",
    SOHM_ADDRESS: "0x1A6b6d1e60AE52f148F26190A9Ee3e4d33F0e9A4",
    DISTRIBUTOR_ADDRESS: "0x15605aF90f01478048B061618EdAAE3Dd92e081A",
    BONDINGCALC_ADDRESS: "0x3598ccf33d50627D68e7bD866EeB0A2F0382e134",
    TREASURY_ADDRESS: "0x65A1ebcf3E1D7620b657F446E73b967b7D26FA22",
    USDT_ADDRESS: "0x55d398326f99059fF775485246999027B3197955", //
    DAO_ADDRESS: '0xA067054d7Ef642d4c0Da0fd7D2D6ab37DbC2A4FD',
    LP_ADDRESS: '0xD27B3FDAfe106caF4FcBB73867b5c1D27c29dcED',
    REDEEM_HELPER_ADDRESS: "0x04Fe9b865697E22B764F515cBD4164f166B3E9CA",
    ALPHA_TOKEN_ADDRESS: '0xe86726506B524c3F60e2b4783123A20266BABf89', //
    MIGRATION_ADDRESS:'0x4c84362007b0013DF2Bd50B3a4D0B5c2ED591b53',
    INVITE_ADDRESS:'0x02f0c60665720694FE5B77A863E39b20ec0460E6',
    TEAM_ADDRESS:'0x1F47a12a45557C0F4D2637c5Aaaa0ff292166BF0',
    TEAM_AMOUNT_ADDRESS:'0x1C3b6e9E9015A19A174DBbea2967282A1C835e23',
  }
};
