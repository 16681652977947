import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Zoom,
} from "@material-ui/core";
import { BondDataCard, BondTableData } from "./BondRow";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useBonds from "../../hooks/Bonds";
import "./choosebond.scss";
import { Skeleton } from "@material-ui/lab";
import ClaimBonds from "./ClaimBonds";
import RewardBonds from "./RewardBonds";
import _ from "lodash";
import { allBondsMap } from "src/helpers/AllBonds";
import { formatCurrency, trim } from "../../helpers";
import { useTranslation } from 'react-i18next';

function ChooseBond() {
  const { t } = useTranslation();
  const { bonds } = useBonds();
  const isSmallScreen = useMediaQuery("(max-width: 733px)"); // change to breakpoint query
  const isVerySmallScreen = useMediaQuery("(max-width: 420px)");
  const isAppLoading = useSelector(state => state.app.loading);
  const isAccountLoading = useSelector(state => state.account.loading);
  
  const accountBonds = useSelector(state => {
    const withInterestDue = [];
    for (const bond in state.account.bonds) {
      if (state.account.bonds[bond].interestDue > 0) {
        withInterestDue.push(state.account.bonds[bond]);
      }
    }
    return withInterestDue;
  });

  const superAccountBonds = useSelector(state => {
    const withInterestDue = [];
    for (const bond in state.account.superBonds) {
      if (state.account.superBonds[bond].interestDue > 0) {
        withInterestDue.push(state.account.superBonds[bond]);
      }
    }
    return withInterestDue;
  });

  const marketPrice = useSelector(state => {
    return state.app.marketPrice;
  });
  const treasuryBalance = useSelector(state => {
    if (state.bonding.loading == false) {
      let tokenBalances = 0;
      for (const bond in allBondsMap) {
        if (state.bonding[bond]) {
          if (state.bonding[bond].bond != "sohm_wbnb_lp_old") {
            tokenBalances += state.bonding[bond].purchased;
          }
        }
      }
      return tokenBalances + 200000;
    }
  });

  return (
    <div id="choose-bond-view">
      {!isSmallScreen && !isAccountLoading && !_.isEmpty(accountBonds) && <ClaimBonds activeBonds={accountBonds} />}

      <Zoom in={true}>
        <Paper className="ohm-card">
          <Box className="card-header">
            <Typography variant="h5">{t('bond.bond')} (1,1)</Typography>
          </Box>

          <Grid container item xs={12} style={{ margin: "10px 0px 20px" }} className="bond-hero">
            <Grid item xs={6}>
              <Box textAlign={`${isVerySmallScreen ? "left" : "center"}`}>
                <Typography variant="h5" color="textSecondary">
                  {t('bond.treasuryBalance')}
                </Typography>
                <Typography variant="h4">
                  {isAppLoading ? (
                    <Skeleton width="180px" />
                  ) : (
                    // new Intl.NumberFormat("en-US", {
                    //   style: "currency",
                    //   currency: "USD",
                    //   maximumFractionDigits: 0,
                    //   minimumFractionDigits: 0,
                    // }).format(treasuryBalance)
                    `$${new Intl.NumberFormat("en-US").format(trim(treasuryBalance))}`
                  )}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={6} className={`ohm-price`}>
              <Box textAlign={`${isVerySmallScreen ? "right" : "center"}`}>
                <Typography variant="h5" color="textSecondary">
                  {t('bond.INDPrice')}
                </Typography>
                <Typography variant="h4">
                  {isAppLoading ? <Skeleton width="100px" /> : `$${trim(marketPrice, 2)}`}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          {!isSmallScreen && (
            <Grid container item>
              <TableContainer>
                <Table aria-label="Available bonds">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">{t('bond.bond')}</TableCell>
                      <TableCell align="left">{t('bond.price')}</TableCell>
                      <TableCell align="left">{t('bond.ROI')}</TableCell>
                      <TableCell align="right">{t('bond.purchased')}</TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bonds.map(bond => (
                      <BondTableData key={bond.name} bond={bond} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Paper>
      </Zoom>

      {isSmallScreen && (
        <Box className="ohm-card-container" style={{marginBottom: 30, width: '100%'}}>
          <Grid container container spacing={2}>
            {bonds.map(bond => (
                <BondDataCard key={bond.name} bond={bond} />
            ))}
          </Grid>
        </Box>
      )}

      {isSmallScreen && !isAccountLoading && !_.isEmpty(accountBonds) && <ClaimBonds activeBonds={accountBonds} />}

      {!isAccountLoading && !_.isEmpty(superAccountBonds) && <RewardBonds activeBonds={superAccountBonds} />}
    </div>
  );
}

export default ChooseBond;
