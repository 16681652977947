import { openWarningNotification } from '../utils/tip.js';

export const addTokenToWallet = async (tokenAddress) => {
  if (window.ethereum) {
      try {
          await window.ethereum.request({
              method: "wallet_watchAsset",
              params: {
                  type: "ERC20",
                  options: {
                      address: tokenAddress,
                      symbol: 'alphaToken',
                      decimals: 9,
                      image: imageURL,
                  },
              },
          });
      } catch (error) {
          openWarningNotification(error.message)
      }
  }
};

export const getQueryString = (name) => {
	let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
	let r = window.location.search.substr(1).match(reg);
	if (r != null) {
			return decodeURIComponent(r[2]);
	};
	return null;
}
