//import LogoIcon from "../../assets/logo.svg";
import "./notfound.scss";

export default function NotFound() {
  return (
    <div id="not-found">
      <div className="not-found-header">
        <h4>Page not found</h4>
      </div>
    </div>
  );
}
