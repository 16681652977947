import { ethers } from 'ethers'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { addresses } from 'src/constants';
import { IJsonRPCError } from 'src/slices/interfaces';
import { clearPendingTxn, fetchPendingTxns } from 'src/slices/PendingTxnsSlice';
import { useWeb3Context } from '.';
import { abi as aSOHMMigration } from "../abi/aSOHMMigration.json";
import { error } from "../slices/MessagesSlice";
import imageURL from '../assets/tokens/INDLogo.png'
import { abi as ierc20Abi } from "../abi/IERC20.json";
export default function useIdo() {

    const dispatch = useDispatch()
    const [alphaBalance, setAlphaBalance] = useState('0')
    const [iceBalance, setIceBalance] = useState('0')
    const { address, provider, chainID } = useWeb3Context()
    const [isApproving, setApproving] = useState(false)
    useEffect(() => {
        initData().then()
    }, [address, provider, chainID])

    const initData = useCallback(
        async () => {
            if (provider && chainID && address) {
                try {
                    const aiceContract = new ethers.Contract(addresses[chainID].ASOHM_ADDRESS, ierc20Abi, provider)
                    const alphaBalance = await aiceContract.balanceOf(address)
                    const tokendecimals = await aiceContract.decimals()
                    setAlphaBalance(ethers.utils.formatUnits(alphaBalance, tokendecimals))
                    const iceContract = new ethers.Contract(addresses[chainID].OHM_ADDRESS, ierc20Abi, provider)
                    const token1decimals = await iceContract.decimals()
                    const iceBalance = await iceContract.balanceOf(address)
                    setIceBalance(ethers.utils.formatUnits(iceBalance, token1decimals))
                    const isApprove = await aiceContract.allowance(address, addresses[chainID].ASOHM_MIGRATION_ADDRESS)
                    setApproving(isApprove > 0)
                } catch (error) {
                    // console.log(error)
                }
            }
        },
        [address, provider, chainID])

    const addTokenToWallet = async () => {
        if (window.ethereum) {
            try {
                await window.ethereum.request({
                    method: "wallet_watchAsset",
                    params: {
                        type: "ERC20",
                        options: {
                            address: addresses[chainID].OHM_ADDRESS,
                            symbol: 'IND',
                            decimals: 9,
                            image: imageURL,
                        },
                    },
                });
            } catch (e: unknown) {
                const rpcError = e as IJsonRPCError;
                if (rpcError.data) {
                    dispatch(error(rpcError.data.message))
                } else {
                    dispatch(error(rpcError.message))
                }
            }
        }
    }
    const DoApprove = useCallback(
        async () => {
            if (address && provider && chainID) {
                const signer = provider.getSigner()
                const asohContract = new ethers.Contract(addresses[chainID].ASOHM_ADDRESS, ierc20Abi, signer)
                let tx;
                try {
                    tx = await asohContract.approve(
                        addresses[chainID].ASOHM_MIGRATION_ADDRESS,
                        ethers.utils.parseUnits("1000000000", "gwei").toString()
                    )
                    dispatch(
                        fetchPendingTxns({ txnHash: tx.hash, text: "Approve_IND", type: "approve_ind" })
                    );
                    await tx.wait()
                    return tx
                } catch (e: unknown) {
                    const rpcError = e as IJsonRPCError;
                    if (rpcError.data) {
                        dispatch(error(rpcError.data.message))
                    } else {
                        dispatch(error(rpcError.message))
                    }
                } finally {
                    if (tx) {
                        initData().then()
                        dispatch(clearPendingTxn(tx.hash));
                    }
                }
            }
        },
        [address, provider, chainID],
    )
    const handlerMigrate = useCallback(async () => {
        let tx;	// Migrate
        try {
            const signer = provider.getSigner();
            const migrationContract = new ethers.Contract(addresses[chainID].ASOHM_MIGRATION_ADDRESS, aSOHMMigration, signer)
            tx = await migrationContract.migrate()
            dispatch(
                fetchPendingTxns({ txnHash: tx.hash, text: "Claim_IND", type: "claim_ind" }),
            );
            await tx.wait();
            return tx
        } catch (e: unknown) {
            const rpcError = e as IJsonRPCError;
            if (rpcError.data) {
                dispatch(error(rpcError.data.message))
            } else {
                dispatch(error(rpcError.message))
            }
        } finally {
            if (tx) {
                initData().then()
                await addTokenToWallet()
                dispatch(clearPendingTxn(tx.hash));
            }
        }
    }, [address, provider, chainID])

    return { alphaBalance, iceBalance, isApproving, DoApprove, handlerMigrate }
}



