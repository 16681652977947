import { SvgIcon, Link } from "@material-ui/core";
// import { ReactComponent as GitHub } from "../../assets/icons/github.svg";
// import { ReactComponent as Medium } from "../../assets/icons/medium.svg";
// import { ReactComponent as Twitter } from "../../assets/icons/twitter.svg";
// import { ReactComponent as Temgran } from "../../assets/icons/Temgran.svg";
import Discord from "../../assets/icons/discord.png";
import Twitter from "../../assets/icons/twitter.png";
import Github from "../../assets/icons/github.png";
import Medium from "../../assets/icons/medium.png";

export default function Social() {
  return (
    <div className="social-row" >
      <Link href="https://discord.gg/infinitydao"  target="_blank">
        <img src={Discord} alt="" style={{width: 20, height: 20}} />
        {/* <SvgIcon color="action" style={{color:"#000000"}} component={Discord} /> */}
      </Link>

      <Link href="https://twitter.com/InDAO_money"  target="_blank">
        <img src={Twitter} alt="" style={{width: 20, height: 20}} />
        {/* <SvgIcon color="primary" style={{color:"#000000"}} component={Twitter} /> */}
      </Link>

      <Link href="https://github.com/InDAOmoney" target="_blank">
        <img src={Github} alt="" style={{width: 20, height: 20}} />
        {/* <SvgIcon  color="primary"  style={{color:"#000000"}} component={GitHub} /> */}
      </Link>
      {/* todo */}
      <Link href="https://medium.com/@indao.money_59297" target="_blank">
        <img src={Medium} alt="" style={{width: 20, height: 20}} />
        {/* <SvgIcon color="primary" style={{color:"#000000"}} component={Medium} /> */}
      </Link>
    </div>
  );
}
