import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { Modal, Button } from 'antd'
import { Skeleton } from "@material-ui/lab";
import { allBondsMap } from "src/helpers/AllBonds";
import { useAddress } from "../../hooks/web3Context";
import useInvite from '../../hooks/useInvite';
import { getQueryString } from '../../utils'
import { openWarningNotification } from '../../utils/tip.js';
import { trim } from "../../helpers";
import {list} from '../../config/black'
import { useTranslation } from 'react-i18next';

import "./dashboard.scss";

function Dashboard({showPerformance}) {
  const { t } = useTranslation();
  // Use marketPrice as indicator of loading.
	const {pathname} = useLocation()
  const address = useAddress();
  const { nodeAddress, gcAddress, total, group, AcceptInvite } = useInvite()
	const [inviteAddress, setInviteAddress] = useState()
	const [visible, setVisible] = useState(false)
	const [invitePending, setInvitePending] = useState(false)
  const isAppLoading = useSelector(state => !state.app?.marketPrice ?? true);
  
  const ohmBalance = useSelector(state => {
    return state.account.balances && state.account.balances.ohm;
  });
  const sohmBalance = useSelector(state => {
    return state.account.balances && state.account.balances.sohm;
  });
  const marketPrice = useSelector(state => {
    return state.app.marketPrice;
  });
  const stakingTVL = useSelector(state => {
    return state.app.stakingTVL;
  });
  const treasuryBalance = useSelector(state => {
    if (state.bonding.loading == false) {
      let tokenBalances = 0;
      for (const bond in allBondsMap) {
        if (state.bonding[bond]) {
          if (state.bonding[bond].bond != "sohm_wbnb_lp_old") {
            tokenBalances += state.bonding[bond].purchased;
          }
        }
      }
      return tokenBalances + 200000;
    }
  });
  const currentIndex = useSelector(state => {
    return state.app.currentIndex;
  });
  const marketCap = useSelector(state => {
    return state.app.marketCap;
  });

  useEffect(() => {
		const address = getQueryString('address')
		if(address) {
			setInviteAddress(address)
			setVisible(true)
		}
	}, [pathname, address])

  const handleCloseModal = () => {
		setVisible(false)
		// window.location.href = window.location.href.split('?')[0]
		// window.location.reload()
	}

	const acceptInvite = () => {
		if(address) {
      if(address === inviteAddress) {
        openWarningNotification("Same Address")
        setVisible(false)
        // window.location.href = window.location.href.split('?')[0]
        return
      }
			setInvitePending(true)
			AcceptInvite(inviteAddress).then(tx => {
				setInvitePending(false)
				if(tx) {
					setVisible(false)
					window.location.href = window.location.href.split('?')[0]
					// window.location.reload()
				}
			})
		} else {
			openWarningNotification('Connect Wallet')
		}
	}

  return (
    <div className="dashboard-view">

      {
        address && (nodeAddress || gcAddress) && showPerformance ?
          <div className="flex flex-justify-content-between flex-align-items-center dashboard-item">
            <div className="item-name">{t('dashboard.total')}</div>
            <div className="item-amount">
              {
                total ?
                  (
                    new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    }).format(total / Math.pow(10, 18)) + ' USDT'
                  ) : (
                    <Skeleton width="150px" />
                  )
              }
            </div>
          </div> : null
      }

      {
        address && (nodeAddress || gcAddress) && showPerformance ?
          <div className="flex flex-justify-content-between flex-align-items-center dashboard-item">
            <div className="item-name">{t('dashboard.group')}</div>
            <div className="item-amount">
              {
                group ?
                  (
                    new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    }).format(group / Math.pow(10, 18)) + ' USDT'
                  ) : (
                    <Skeleton width="150px" />
                  )
              }
            </div>
          </div> : null
      }

      {/* {
        list.includes(address) ?
          <div className="flex flex-justify-content-between flex-align-items-center dashboard-item">
            <div className="item-name">{t('dashboard.lockValue')}</div>
            <div className="item-amount">
              {
                !address ? '0 sIND'
                :
                currentIndex ?
                  (
                    trim(currentIndex * 10, 2) + ' sIND'
                  ) : (
                    <Skeleton width="150px" />
                  )
              }
            </div>
          </div> : null
      } */}

      <div className="flex flex-justify-content-between flex-align-items-center dashboard-item">
        <div className="item-name">{t('dashboard.balance')}</div>
        <div className="item-amount">
          {
            !address ? '0 IND'
            :
              ohmBalance ?
              (
                trim(ohmBalance, 4) + ' IND'
              ) : (
                <Skeleton width="150px" />
              )
          }
        </div>
      </div>

      <div className="flex flex-justify-content-between flex-align-items-center dashboard-item">
        <div className="item-name">{t('dashboard.staked')}</div>
        <div className="item-amount">
          {
            !address ? '0 sIND'
            :
              sohmBalance ?
              (
                trim(sohmBalance, 4) + ' sIND'
              ) : (
                <Skeleton width="150px" />
              )
          }
        </div>
      </div>

      <div className="flex flex-justify-content-between flex-align-items-center dashboard-item">
        <div className="item-name">{t('dashboard.INDPrice')}</div>
        <div className="item-amount">
          {
            marketPrice ?
            (
              trim(marketPrice, 4) + ' USDT'
            ) : (
              <Skeleton width="150px" />
            )
          }
        </div>
      </div>

      <div className="flex flex-justify-content-between flex-align-items-center dashboard-item">
        <div className="item-name">{t('dashboard.circulatingMarketCap')}</div>
        <div className="item-amount">
          {marketCap ?
            (
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              }).format(marketCap) + ' USDT'
            ) : (
              <Skeleton width="150px" />
            )}
        </div>
      </div>

      <div className="flex flex-justify-content-between flex-align-items-center dashboard-item">
        <div className="item-name">{t('dashboard.totalValueLocked')}</div>
        <div className="item-amount">
          {stakingTVL ?
            (
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              }).format(stakingTVL) + ' USDT'
            ) : (
              <Skeleton width="150px" />
            )}
        </div>
      </div>

      <div className="flex flex-justify-content-between flex-align-items-center dashboard-item">
        <div className="item-name">{t('dashboard.treasuryBalance')}</div>
        <div className="item-amount">
          {
            treasuryBalance
            ?
            `$${new Intl.NumberFormat("en-US").format(trim(treasuryBalance))} USDT`
            :
            <Skeleton width="150px" />
          }
        </div>
      </div>

      <div className="flex flex-justify-content-between flex-align-items-center dashboard-item">
        <div className="item-name">{t('dashboard.index')}</div>
        <div className="item-amount">
          {
            currentIndex
            ?
            (currentIndex / 10).toFixed(2) + ' IND'
            : 
            <Skeleton width="150px" />
          }
        </div>
      </div>
      <Modal visible={visible} centered={true} footer={null} onCancel={handleCloseModal}>
				<div className="invite-modal">
					<div className="invite-modal-title">Notice</div>
					<div className="flex flex-justify-content-center flex-align-items-center invite-modal-content">Please confirm the binding invitation relationship</div>
					<div className="flex flex-justify-content-center">
						<Button className="buyBtn" loading={invitePending} onClick={() => acceptInvite()}>Yes</Button>
					</div>
				</div>
			</Modal>
    </div>
  );
}

export default Dashboard;
