import { useState, useEffect } from "react";
import { Link, SvgIcon, Popper, Button, Paper, Typography, Divider, Box, Fade, Slide } from "@material-ui/core";
import { useAddress } from "src/hooks/web3Context";
import useInvite from '../../hooks/useInvite'
import "./switchLang.scss";
import { useTranslation } from 'react-i18next';

function GCDCSwitch({showPerformance, switchPerformance}) {
  const { t } = useTranslation();
  const address = useAddress();
  const { nodeAddress, gcAddress } = useInvite()

  const handleSwitch = () => {
    switchPerformance(!showPerformance)
  }
  return (
    address && (nodeAddress || gcAddress) ?
    <Box
      component="div"
      id="ohm-menu-button-hover"
    >
      <Button id="ohm-menu-button" size="large" variant="contained" color="secondary" title="IND" onClick={handleSwitch}>
        <Typography>{showPerformance ? t('genesis.hidden') : t('genesis.show')}</Typography>
      </Button>
    </Box>
    : null
  );
}

export default GCDCSwitch;
