import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useCallback, useEffect, useState } from "react";
import { Skeleton } from "@material-ui/lab";
import { styled } from "@material-ui/core/styles";
import { trim, formatCurrency } from "../../helpers";
import { useDispatch, useSelector } from "react-redux";
import {
	Box,
	Grid,
	Paper,
	Typography,
	Zoom,
	Stack,
	Button,
	Tooltip,
	Popper,
	FormControl,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Slider
} from "@material-ui/core";
import tipsIcon1 from "../../assets/icons/tips_light.png";
import tipsIcon2 from "../../assets/icons/tips_black.png";

import "./snowglobe.scss";

import { useTranslation } from 'react-i18next';

function Snowglobe({ theme }) {
  const { t } = useTranslation();
	const tipsIcon = theme === "light" ? tipsIcon1 : tipsIcon2;
	const isSmallScreen = useMediaQuery("(max-width: 733px)"); // change to breakpoint query
	const isVerySmallScreen = useMediaQuery("(max-width: 420px)");

	const [amount, setAmount] = useState(1);
	const [rewardYield, setRewardYield] = useState(0.9);
	const [currentPrice, setCurrentPrice] = useState(10);
	const [futurePrice, setFuturePrice] = useState(100);
	const [silderVal, setSilderVal] = useState(30);

	const [anchorEl, setAnchorEl] = useState(null);
	const handleClick = event => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};
	const open = Boolean(anchorEl);
	const id = "ohm-popper";

	const isAppLoading = useSelector(state => state.app.loading);
	const stakingRebase = useSelector(state => {
		return state.app.stakingRebase;
	});
	const sohmBalance = useSelector(state => {
		return state.account.balances && state.account.balances.sohm ? state.account.balances.sohm : 0;
	});
	const marketPrice = useSelector(state => {
		return state.app.marketPrice > 0 ? trim(state.app.marketPrice, 2) : 10;
	});
	const stakingRebasePercentage = stakingRebase > 0 ? trim(stakingRebase * 100, 4) : 0.9;

	useEffect(() => {
		setCurrentPrice(marketPrice)
		setFuturePrice(marketPrice)
	}, [marketPrice])

	useEffect(() => {
		setRewardYield(stakingRebasePercentage)
	}, [stakingRebasePercentage])

	const setMax = (name) => {
		if (name == 'amount') {
			setAmount(sohmBalance);
		} else if (name == 'rewardYield') {
			setRewardYield(stakingRebasePercentage);
		} else if (name == 'currentPrice') {
			setCurrentPrice(marketPrice);
		} else if (name == 'futurePrice') {
			setFuturePrice(marketPrice);
		} else {
			return;
		}
	};

	const handleSilderChange = (event, newValue) => {
		if (typeof newValue === 'number') {
			setSilderVal(newValue);
		}
	};

	return (
		<div id="stake-view">
			<Zoom in={true}>
				<Paper className="ohm-card">
					<Box className="card-header">
						<Typography variant="h5">{t('calculator.estimateYour')}</Typography>
					</Box>
					<Grid container item xs={12} className="header-container">
						<Grid item xs={12} sm={4} md={4} lg={4}>
							<Box display="flex" alignItems="center" flexDirection="column">
								<Typography variant="h6" color="textSecondary">
									{t('calculator.currentINDPrice')}
								</Typography>
								<Typography variant="h4">
									{isAppLoading ? <Skeleton width="150px" /> : <>{`$${marketPrice}`}</>}
								</Typography>
							</Box>
						</Grid>

						<Grid item xs={12} sm={4} md={4} lg={4} style={{ marginTop: `${isVerySmallScreen ? "8px" : "0px"}` }}>
							<Box display="flex" alignItems="center" flexDirection="column">
								<Box display="flex" alignItems="center">
									<Typography variant="h6" color="textSecondary">
										{t('calculator.nextRewardYied')}
									</Typography>
									<img src={tipsIcon} style={{ wdth: "16px", height: "16px", marginLeft: "10px" }}
										onMouseEnter={e => handleClick(e)}
										onMouseLeave={e => handleClick(e)}
										id="ohm-menu-button-hover" ></img>
									<Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-start" transition>
										<Paper className="ohm-menu" elevation={1}>
											<Typography variant="h6">
												The reward yield will change over time.
											</Typography>
										</Paper>
									</Popper>
								</Box>
								<Typography variant="h4">
									{isAppLoading ? <Skeleton width="150px" /> : <>{stakingRebasePercentage}%</>}
								</Typography>
							</Box>
						</Grid>

						<Grid item xs={12} sm={4} md={4} lg={4} style={{ marginTop: `${isVerySmallScreen ? "8px" : "0px"}` }}>
							<Box display="flex" alignItems="center" flexDirection="column">
								<Typography variant="h6" color="textSecondary">
									{t('calculator.yoursINDBalance')}
								</Typography>
								<Typography variant="h4">
									{isAppLoading ? <Skeleton width="150px" /> : <>{trim(sohmBalance, 4)} IND</>}
								</Typography>
							</Box>
						</Grid>
					</Grid>

					<Box display="flex" className="snowglobe-container">
						<Grid container item className="header-container">
							<Grid item xs={12} sm={7}>
								<Grid container item>
									<Grid item xs={9}>
										<FormControl className="ohm-input" variant="outlined" color="primary">
											<Box display="flex" justifyContent="center" className="form-label">
												<Typography variant="h6">
													{t('calculator.sINDAmount')}
												</Typography>
											</Box>
											<OutlinedInput
												id="outlined-adornment-amount"
												autoFocus={true}
												type="number"
												placeholder="Enter IND Amount"
												className="snowglobe-input"
												value={amount}
												onChange={e => setAmount(e.target.value >= 10000 ? 10000 : e.target.value)}
												// startAdornment={<InputAdornment position="start">$</InputAdornment>}
												labelWidth={0}
												endAdornment={
													<InputAdornment position="end">
														<Button variant="text" onClick={() => { setMax('amount') }}>
															{t('common.max')}
														</Button>
													</InputAdornment>
												}
											/>
											<Box display="flex" justifyContent="center" className="form-label">
												<Typography variant="h6">
													{t('calculator.rewardYield')}
												</Typography>
											</Box>
											<OutlinedInput
												id="outlined-adornment-reward"
												type="number"
												placeholder="Enter Reward Yield (%)"
												className="snowglobe-input"
												value={rewardYield}
												onChange={e => setRewardYield(e.target.value >= 100 ? 100 : e.target.value)}
												// startAdornment={<InputAdornment position="start">$</InputAdornment>}
												labelWidth={0}
												endAdornment={
													<InputAdornment position="end">
														<Button variant="text" onClick={() => { setMax('rewardYield') }}>
															{t('common.current')}
														</Button>
													</InputAdornment>
												}
											/>
											<Box display="flex" justifyContent="center" className="form-label">
												<Typography variant="h6">
													{t('calculator.INDPriceAt')}
												</Typography>
											</Box>
											<OutlinedInput
												id="outlined-adornment-amount"
												type="number"
												placeholder="Enter buy price"
												className="snowglobe-input"
												value={currentPrice}
												onChange={e => setCurrentPrice(e.target.value >= 20000 ? 20000 : e.target.value)}
												// startAdornment={<InputAdornment position="start">$</InputAdornment>}
												labelWidth={0}
												endAdornment={
													<InputAdornment position="end">
														<Button variant="text" onClick={() => { setMax('currentPrice') }}>
															{t('common.current')}
														</Button>
													</InputAdornment>
												}
											/>
											<Box display="flex" justifyContent="center" className="form-label">
												<Typography variant="h6">
													{t('calculator.futureINDMarket')}
												</Typography>
											</Box>
											<OutlinedInput
												id="outlined-adornment-future"
												type="number"
												placeholder="Enter future price"
												className="snowglobe-input"
												value={futurePrice}
												onChange={e => setFuturePrice(e.target.value >= 20000 ? 20000 : e.target.value)}
												// startAdornment={<InputAdornment position="start">$</InputAdornment>}
												labelWidth={0}
												endAdornment={
													<InputAdornment position="end">
														<Button variant="text" onClick={() => { setMax('futurePrice') }}>
															{t('common.current')}
														</Button>
													</InputAdornment>
												}
											/>
										</FormControl>
									</Grid>
									<Grid item xs={3} className="sliderContainer">
										<Box className="sliderDesc" display="flex" alignItems="center" flexDirection="column"
											style={{ marginTop: `${isVerySmallScreen ? "16px" : "8px"}` }}>
											<Typography variant="h6">
												{silderVal}
											</Typography>
											<Typography variant="h6">
												{t('common.days')}
											</Typography>
										</Box>
										<Slider
											value={silderVal}
											min={1}
											step={1}
											max={365}
											// color="#07004A"
											orientation="vertical"
											onChange={handleSilderChange}
											valueLabelDisplay="auto"
											aria-labelledby="non-linear-slider"
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12} sm={5}>
								<Box className="info" display="flex" justifyContent="center" flexDirection="column">
									<Box display="flex" justifyContent="space-between" alignItems="center" className="info-item">
										<Typography variant="h6">
											{t('calculator.yourInitial')}
										</Typography>
										<Typography variant="h6">
											{trim(amount * currentPrice, 2)} USDT
										</Typography>
									</Box>
									<Box display="flex" justifyContent="space-between" alignItems="center" className="info-item">
										<Typography variant="h6">
											{t('calculator.currentWealth')}
										</Typography>
										<Typography variant="h6">
											{trim(marketPrice, 2)} USDT
										</Typography>
									</Box>
									<Box display="flex" justifyContent="space-between" alignItems="center" className="info-item">
										<Typography variant="h6">
											{t('calculator.INDRewards')}
										</Typography>
										<Typography variant="h6">
											{trim(amount * Math.pow((1 + rewardYield / 100), 3 * silderVal) - amount, 2).toString()} IND
										</Typography>
									</Box>
									<Box display="flex" justifyContent="space-between" alignItems="center" className="info-item">
										<Typography variant="h6">
											{t('calculator.potentialReturn')}
										</Typography>
										<Typography variant="h6">
											{trim(amount * Math.pow((1 + rewardYield / 100), 3 * silderVal) * futurePrice, 2).toString()} USDT
										</Typography>
									</Box>
									<Box display="flex" justifyContent="space-between" alignItems="center" className="info-item">
										<div>
											<Typography variant="h6">
											{t('calculator.potentialNumber')}
											</Typography>
											<Typography variant="h6" color="textSecondary">
												Lamborghini Huracán, no options
											</Typography>
										</div>
										<Typography variant="h6">
											{trim(amount * Math.pow((1 + rewardYield / 100), 3 * silderVal) * futurePrice / 200000, 2).toString()}
										</Typography>
									</Box>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Paper>
			</Zoom>
		</div>
	);
}

export default Snowglobe;