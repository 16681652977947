import React, { useState, useEffect, useCallback } from 'react'
import { useWeb3Context } from './web3Context'
import { ethers } from 'ethers'
import { abi as INVITEABI } from '../config/abi/invite.json'
import { abi as TEAMABI } from '../abi/team.json'
import { addresses } from 'src/constants';
import { openWarningNotification, openSuccessNotification } from '../utils/tip.js';

export default function useInvite() {

	const { address, provider, chainID } = useWeb3Context()
	const [salePrice, setSalePrice] = useState()
	const [saleGcPrice, setSaleGcPrice] = useState()
	const [superiorAddresses, setSuperiorAddresses] = useState()
	const [nodeAddress, setNodeAddress] = useState()
	const [gcAddress, setGcAddress] = useState()
	const [total, setTotal] = useState()
	const [group, setGroup] = useState()

	useEffect(() => {
		address && initData()
	}, [address, provider, chainID])

	const initData = useCallback(
		async () => {
			const inviteContract = new ethers.Contract(addresses[chainID].INVITE_ADDRESS, INVITEABI, provider)
			const teamContract = new ethers.Contract(addresses[chainID].TEAM_ADDRESS, TEAMABI, provider)
			const superiorAdd = await inviteContract.superiorAddress(address)
			setSuperiorAddresses(superiorAdd)
			const nodeAdd = await inviteContract.nodeAddress(address)
			setNodeAddress(nodeAdd)
			const gcAdd = await inviteContract.gcAddress(address)
			setGcAddress(gcAdd)
			const total = await teamContract.queryAmount(address)
			setTotal(total)
			let group
			if(nodeAdd) {
				group = await inviteContract.bondNodeUAmount(address)
			} else if(gcAdd) {
				group = await inviteContract.bondGcUAmount(address)
			}
			setGroup(group)
		},
		[address, provider, chainID],
	)

	const AcceptInvite = useCallback(
		async (superAddress) => {
			const signer = provider.getSigner()
			const inviteContract = new ethers.Contract(addresses[chainID].INVITE_ADDRESS, INVITEABI, signer)
			const ifInvited = await inviteContract.memberAddress(address)

			if(ifInvited) return openWarningNotification('Already operated')
			let tx;
			try {
				tx = await inviteContract.inviteAddress(superAddress)
				await tx.wait()
				return tx
			} catch (e) {
				if (e.data) {
					openWarningNotification(e.data.message)
				} else {
					openWarningNotification(e.message)
				}
			}
		},
		[address, provider, chainID],
	)

	const DoClaim = useCallback(
		async (superAddress) => {
			const signer = provider.getSigner()
			const inviteContract = new ethers.Contract(addresses.invite[chainID], INVITEABI, signer)
			let tx;
			try {
				tx = await inviteContract.purchaseaNode(superAddress, {value: salePrice})
				await tx.wait()
				return tx
				// tx = await inviteContract.inviteAddress(superAddress)
				// await tx.wait()
				// return tx
			} catch (e) {
				if (e.data) {
					openWarningNotification(e.data.message)
				} else {
					openWarningNotification(e.message)
				}
			}
		},
		[address, provider, chainID, salePrice],
	)

	const DoClaimGc = useCallback(
		async (superAddress) => {
			const signer = provider.getSigner()
			const inviteContract = new ethers.Contract(addresses.invite[chainID], INVITEABI, signer)
			let tx;
			try {
				tx = await inviteContract.purchaseaGc(superAddress, {value: saleGcPrice})
				await tx.wait()
				return tx
				// tx = await inviteContract.inviteAddress(superAddress, {value: salePrice})
				// await tx.wait()
				// return tx
			} catch (e) {
				if (e.data) {
					openWarningNotification(e.data.message)
				} else {
					openWarningNotification(e.message)
				}
			}
		},
		[address, provider, chainID, saleGcPrice],
	)

	return { superiorAddresses, nodeAddress, gcAddress, total, group, AcceptInvite, DoClaim, DoClaimGc }
}
